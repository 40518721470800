<template>
  <v-card>
    <v-card-title>Create a new meal component</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')">Cancel</v-btn>
      <v-btn
        @click="saveComponent"
      >Save
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-form ref="componentForm"
              v-model="valid"
              lazy-validation
      >

        <v-container pa-0 ma-0>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="componentName"
                label="Component Name"
                :rules="componentNameRules"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="monosized"
                label="monosized"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">Select the ingredients that you'd like to move into this component.
              If you don't select any ingredients a new component will be created.</v-col>
            <v-col cols="4" v-for="(component,name) of ingredientsByComponent" v-bind:key="component.id">
              <h4>
                {{ name }}
              </h4>

              <v-list dense>
                <v-list-item
                  v-for="(ingredient,index) of component.ingredients"
                  v-bind:key="index"
                  dense
                  class="pa-0 ma-0"
                >
                  <v-checkbox
                    class="pa-0 ma-0"
                    v-model="components[keyOf(ingredient)]"
                    :label="formatFood(ingredient)"
                    required
                    dense
                  />
                </v-list-item>
              </v-list>

            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>

</template>

<script>
import {formatWeight} from "@/store/utils";

export default {
  name: "MealToComponent",
  props: {
    ingredientsByComponent: Object,
  },
  computed: {},
  data() {
    return {
      components: {},
      componentName: '',
      componentNameRules: [
        v => !!v || 'Name is required'
      ],
      description: '',
      monosized: false,
      counter: 0,
      valid: true
    }
  },
  methods: {
    async saveComponent() {
      const form = this.$refs.componentForm;
      const validate = form.validate();
      console.log('validate', [validate, form]);
      if (!validate) {
        return;
      }
      this.$set(this, 'counter', this.counter + 1);
      const result = {
        component_id: `new-${this.counter}`,
        component_name: this.componentName,
        ingredientsToMove: Object.assign({}, this.components),
        monosized: this.monosized
      };
      console.log('saveComponent', result);

      form.reset();
      this.$emit('closeAndSave', result);
    },
    formatFood(food) {
      return `${formatWeight(food.cookedAmount)}g ${food.fooddescriptionsimple || food.fooddescription}`;
    },
    keyOf(ingredient) {
      return `${ingredient.component_id}:${ingredient.foodid}`;
    }
  }
}
</script>
