<template>
  <div>
    <v-alert v-if="loading">Loading</v-alert>
    <v-alert v-else-if="notFound">Could not find meal {{ this.mealId }}</v-alert>
    <div v-else>
      <v-dialog v-model="showCopyPrepAssignmentDialog">
        <PrepAssignmentCopy
          :destination="mealId"
          :source="parentMealId"
          v-on:close="showCopyPrepAssignmentDialog=false"
        />
      </v-dialog>
      <v-toolbar outlined height="90px;">
        <span class="ml-0 pl-0 mr-4" v-if="!dense && showNutrientControls">
            <v-btn
              class="d-print-none"
              icon
              x-small
              @click="showNutrients(ingredients, 'small')"
            >
              <v-icon>mdi-gauge</v-icon>
            </v-btn>
          <v-btn
            class="d-print-none"
            icon
            @click="showNutrients(ingredients)"
          >
              <v-icon>mdi-gauge</v-icon>
            </v-btn>
                      <v-btn
                        class="d-print-none"
                        icon
                        x-large
                        @click="showNutrients(ingredients, 'large')"
                      >
              <v-icon>mdi-gauge</v-icon>
            </v-btn>

          </span>
        <v-text-field class="pa-8" v-if="isNameEdit"
                      label="Meal Name"
                      v-model="meal.name"
                      @change="saveEditName"
        />
        <v-toolbar-title>
          <span v-if="isSubMeal && !isNameEdit && showPrepControls">
            <router-link :to="{ params: {  id: `${parentMealId}` } }">
              Previous Version {{ parentMealId }}
            </router-link>
            &rarr;
          </span>

          <span v-if="!isNameEdit">
            <v-chip v-if="meal.diet" text-color="white" small :color="getDietColor(meal.diet)">
              {{ getDietName(meal.diet) }}
            </v-chip>
            {{ meal.name }} ({{ meal.id }})
            <v-icon v-if="meal.is_selected" color="yellow">mdi-star</v-icon>
            <v-icon v-if="meal.is_draft" color="blue">mdi-star</v-icon>
            <span v-if="meal.sku">[ <router-link :to="{name:'MealVersions',query: {sku: meal.sku}}">{{
                meal.sku
              }}</router-link> ]</span>

            <v-btn
              v-if="allowNameEdit && !readOnly"
              @click="editName"
              icon
            ><v-icon>mdi-pencil</v-icon>
            </v-btn>
          </span>

          <span v-if="submeals && submeals.length>0">&rarr;</span>
          <span v-for="(submeal,index) of submeals" v-bind:key="index">
            <router-link class="mx-1" :to="{ params: {  id: `${submeal.id}` } }">{{ submeal.id }}</router-link>
          </span>
          <span
            v-if="!isNameEdit"
            class="right d-flex"
            style="font-size: small"
          >
            <v-chip small outlined v-if="meal.production_priority" style="font-weight: bolder"
                    class="mr-1">P{{ meal.production_priority }}</v-chip>
            <span v-if="summary">
            small: {{ summary.small }}
            medium: {{ summary.medium }}
            large: {{ summary.large }}
            | </span>
            <span> medium weight: {{
                getCookedAmountTotal(meal.id, {excludeIgnoredPrepIngredients: true}).toFixed()
              }}g </span>
            <span v-if="showMealCost && mealCost">
              | cost: ${{ mealCost.toFixed(2) }}
            </span>
          </span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items
          class="d-print-none"
        >

          <v-btn
            v-if="!dense && !readOnly && !hideCreateComponent"
            small
            text
            @click="showComponentDialog=true"
          >
            Create Component
          </v-btn>
          <v-btn
            v-if="!readOnly && submealFeatureEnabled && !dense && !parentMealId"
            small
            text
            @click="createSub"
          >
            Create Sub
          </v-btn>

          <!--          -->
          <!--          <v-btn-->
          <!--              v-if="!readOnly && !dense && parentMealId"-->
          <!--              small-->
          <!--              text-->
          <!--              @click="deleteMealAndRedirectToParent"-->
          <!--          >-->
          <!--            Delete Sub {{ parentMealId }}-->
          <!--          </v-btn>-->

        </v-toolbar-items>
      </v-toolbar>
      <v-card-actions v-if="showPrepControls">
        <v-spacer/>
        <v-btn v-if="parentMealId" @click="showCopyPrepAssignmentDialog=true">Copy Assignment/Instructions From
          Previous Version {{ parentMealId }}
        </v-btn>
        <v-btn disabled v-if="!parentMealId">Copy Assignment/Instructions: No Previous Version</v-btn>
      </v-card-actions>
      <v-spacer/>
      <!-- only show this card if there are orders -->
      <v-card v-if="!dense && orders.length>0">
        <v-row class="pa-2">
          <v-col class="py-0">
            <v-chip
              class="ma-1"
              :color="isDetected(r.name)?'orange':'primary'"
              small
              v-for="(r,i) of orderRestrictionsChips"
              v-bind:key="i">
              {{ r.count }} {{ r.text }}
              <!--          <v-chip class="ma-1" small v-for="(count,tag) of showTags(restrictedTagIds,c.ingredients)"-->
            </v-chip>
          </v-col>
          <v-col cols="1" class="d-print-none">
            <v-btn icon right @click="showRestrictionDetail=!showRestrictionDetail">
              <span class="caption">detail</span>
              <v-icon v-if="!showRestrictionDetail">mdi-chevron-down</v-icon>
              <v-icon v-if="showRestrictionDetail">mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-row class="px-2 mt-0" v-if="showRestrictionDetail">
            <v-col>
              <MealRestrictionSummary
                :meal="meal"
                :orders="orders"
              />
            </v-col>
          </v-row>
        </v-expand-transition>

        <v-list>
          <v-list-item v-if="parentMeal">
            <router-link class="mx-1" :to="{ params: {  id: `${parentMealId}` } }">{{ parentMealId }}</router-link>
            {{ parentMeal.name }}
            <v-spacer/>
            <v-btn text @click="showChangeOrderDialog(parentMeal)">Select Orders To Sub</v-btn>
          </v-list-item>
          <v-list-item v-for="(submeal,index) of submeals" v-bind:key="index">
            <router-link class="mx-1" :to="{ params: {  id: `${submeal.id}` } }">{{ submeal.id }}</router-link>
            {{ submeal.name }}
            <v-spacer/>
            <v-btn text @click="showChangeOrderDialog(submeal)">Select Orders To Sub</v-btn>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card class="d-print-none">
        <v-card-title>
          Meal Recipe
          <v-btn v-if="!readOnlyRecipe" class="d-print-none" @click="editInstructions=!editInstructions" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="!editInstructions">
          <v-row>
            <v-col v-if="meal.notes">
              <!--              <span class="caption">Recipe for {{ meal.name }}:</span>-->
              <span class="dense" v-for="(line,i) of toLineArray(meal.notes)" v-bind:key="i">{{ line }}<br/></span>
            </v-col>
            <v-col v-if="!meal.notes" class="d-print-none">
              No instructions.
              <span v-if="!readOnlyRecipe">
              Click
              <v-btn @click="editInstructions=!editInstructions" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              to add some.
                </span>
            </v-col>
          </v-row>
          <v-row dense
                 v-for="(c,componentName) of ingredientsByComponent"
                 v-bind:key="c.id"
          >
            <v-col v-if="components[c.id] && components[c.id].notes">
              <span class="caption">Notes for {{ componentName }}: </span>
              <br v-if="toLineArray(components[c.id].notes).length>1"/>
              <span class="dense" v-for="(line,i) of toLineArray(components[c.id].notes)" v-bind:key="i">{{ line }}<br/></span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="editInstructions">
          <v-row>
            <v-col>
              Recipe for {{ meal.name }}
              <v-textarea :disabled="readOnlyRecipe"
                          v-model="meal.notes"
                          auto-grow
                          rows="1"
                          :hint="`enter meal recipe notes`"
                          @change="updateMeal({ mealId, update: { notes: meal.notes}})"
              >
              </v-textarea>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-title v-if="editInstructions">
          Component Instructions
        </v-card-title>
        <v-card-text v-if="editInstructions">
          <v-row
            v-for="(c,componentName) of ingredientsByComponent"
            v-bind:key="c.id"
          >
            <v-col>
              Notes for {{ componentName }} component
              <v-textarea v-if="c.id"
                          :disabled="readOnlyRecipe"
                          dense
                          v-model="components[c.id].notes"
                          auto-grow
                          rows="1"
                          :hint="`enter notes for the meal component ${componentName}`"
                          @change="updateComponent({id: c.id, component: components[c.id]})"
              >
              </v-textarea>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="d-none d-print-block" v-if="hasNotes">
        <v-card-title>
          Meal Recipe
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="meal.notes">
              <span class="dense" v-for="(line,i) of toLineArray(meal.notes)" v-bind:key="i">{{ line }}<br/></span>
            </v-col>
          </v-row>
          <v-row dense
                 v-for="(c,componentName) of ingredientsByComponent"
                 v-bind:key="c.id"
          >
            <v-col v-if="components[c.id].notes">
              <span class="caption">Notes for {{ componentName }}: </span>
              <br v-if="toLineArray(components[c.id].notes).length>1"/>
              <span class="dense" v-for="(line,i) of toLineArray(components[c.id].notes)" v-bind:key="i">{{ line }}<br/></span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>


      <v-card
        v-for="(c,componentName) of ingredientsByComponent"
        v-bind:key="c.id"
        outlined
      >
        <v-card-title v-if="componentName" class="pt-1 ">
          <span class="ml-0 pl-0 mr-4" v-if="!dense && showNutrientControls">
            <v-btn
              class="d-print-none"
              icon
              small
              @click="showNutrients(c.ingredients)"
            >
              <v-icon>mdi-gauge</v-icon>
            </v-btn>
          </span>

          {{ componentName }}
          <router-link class="pl-2" v-if="c.id" :to="{ name: 'ComponentDetail', params: { id: c.id }}">
            {{ `C${c.id}` }}
          </router-link>
          <v-icon color="blue" v-if="components[c.id].verified">mdi-shield-check</v-icon>

          <span class="pl-2" v-if="c.id && components[c.id].category">({{ components[c.id].category }})</span>
          <v-spacer/>
          <span style="font-size: medium" v-if="summary && isMonosized(c.id)">
                  medium: {{ summary.count }}
          </span>
          <span style="font-size: medium" v-if="summary && !isMonosized(c.id)">
                small: {{ summary.small }}
                medium: {{ summary.medium }}
                large: {{ summary.large }}
          </span>
          <v-btn class="d-print-none" v-if="!readOnly" icon @click="removeComponent(c)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container class="py-0 pl-1">
            <v-row>
              <v-col cols="3">
                <v-select
                  v-if="showPrepControls"
                  class="mr-2"
                  dense
                  label="Assigned Team"
                  chips
                  :items="defaultTeams"
                  v-model="assigned.components[c.id]"
                  clearable
                  @change="saveComponentAssignment(c.id, $event)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-chip
                  class="ma-1"
                  :color="isDetected(r.name)?'orange':'primary'"
                  small
                  v-for="(r,i) of filterIngredients(orderRestrictionsChips, c.ingredients)"
                  v-bind:key="i">
                  {{ r.count }} {{ r.text }}
                  <!--          <v-chip class="ma-1" small v-for="(count,tag) of showTags(restrictedTagIds,c.ingredients)"-->
                </v-chip>
                <!--                <v-chip-->
                <!--                    v-for="(count,tag) of showTags(restrictedTagIds,c.ingredients)"-->
                <!--                    v-bind:key="tag"-->
                <!--                    class="ma-1"-->
                <!--                    small-->
                <!--                >-->
                <!--                  &lt;!&ndash; FIXME: should colour based on restriction being detected    :color="isDetected(tag)?'orange':'primary'"&ndash;&gt;-->
                <!--                  {{ count > 0 ? count : count }} {{ tag }}-->
                <!--                </v-chip>-->
              </v-col>
              <v-checkbox
                :disabled="readOnly"
                class="py-0 mt-0"
                v-if="c.id && components[c.id]"
                v-model="components[c.id].monosized"
                dense
                label="monosized"
                @change="updateComponent({id: c.id, component:components[c.id]})"
              />
            </v-row>
            <v-row v-if="c.id && components[c.id].notes">
              <v-col>
                <span class="caption">Component Notes</span>
                <br v-if="toLineArray(components[c.id].notes).length>1"/>
                <span class="dense" v-for="(line,i) of toLineArray(components[c.id].notes)" v-bind:key="i">{{
                    line
                  }}<br/></span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-print-none">
          <v-spacer></v-spacer>
          <v-btn v-if="!readOnly" small text @click="addIngredient(c.id)">Add Ingredient</v-btn>
        </v-card-actions>
        <v-card-text class="pt-0">
          <MealDetailTable
            :meal-id="meal.id"
            :read-only="readOnly"
            :title="componentName"
            :subtitle="summary ? Object.entries(summary).join(' ') : ''"
            :component="components[c.id] || {}"
            :ingredients="c.ingredients"
            :summary="summary"
            :dense="dense"
            :save-ingredient-assignment="saveIngredientAssignment"
            :show-nutrient-controls="showNutrientControls"
            :show-prep-controls="showPrepControls"
            :show-meal-cost="showMealCost"
          />
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="showingChangeOrderDialog"
      >
        <v-card v-if="showingChangeOrderDialog">
          <v-card-title>Select Orders to Move</v-card-title>
          <v-card-text>
            {{ newMeal.name }}
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="4" v-for="(r,i) of orderRestrictionsChips"
                       v-bind:key="i">
                  <v-checkbox
                    v-model="newMealOrders[r.name]"
                    dense
                    :label="`${r.count} ${r.text}${isDetected(r.name)?' *':''}`"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            {{ newMealOrdersSelected.length }} orders
            <v-spacer/>
            <v-btn class="mr-5" @click="showingChangeOrderDialog=false">Cancel</v-btn>
            <v-btn
              @click="saveChangeOrder"
              :disabled="!newMealOrdersSelected"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="!readOnly"
        fullscreen
        v-model="showComponentDialog"
      >
        <MealToComponent
          :ingredientsByComponent="ingredientsByComponent"
          v-on:close="showComponentDialog=false"
          v-on:closeAndSave="createComponentAndMoveIngredients($event)"
        />
      </v-dialog>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

import MealDetailTable from '@/components/MealDetailTable';
import MealToComponent from '@/components/MealToComponent';
import {convertIngredient} from '@/store/utils';
import MealRestrictionSummary from '@/components/MealRestrictionSummary';
import api from '@/api';
import PrepAssignmentCopy from '@/components/PrepAssignmentCopy';

export default {
  components: {
    PrepAssignmentCopy,
    MealRestrictionSummary,
    MealToComponent,
    MealDetailTable
  },
  props: {
    readOnly: {type: Boolean, required: true},
    mealId: {type: [Number, String]},
    date: String,
    dense: Boolean,
    allowNameEdit: {type: Boolean, default: false, required: false},
    // TODO: may want to restrict seeing meal costs by role
    showMealCost: {type: Boolean, default: true, required: false},
    showNutrientControls: {type: Boolean, default: true, required: false},
    showPrepControls: {type: Boolean, default: false, required: false},
    canEditRecipe: {type: Boolean, default: false, required: false},
    hideCreateComponent: {type: Boolean, default: false, required: false},
  },
  data() {
    return {
      loading: true,
      showComponentDialog: false,
      newMealId: null,
      newMealOrders: null,
      showingChangeOrderDialog: false,
      submealFeatureEnabled: false,
      showRestrictionDetail: null,
      editInstructions: false,
      steps: {},
      assigned: {
        components: {},
      },
      showCopyPrepAssignmentDialog: null,
    }
  },
  watch: {
// call again the method if the route changes
//     '$route': 'fetchData',
    mealId: 'fetchData'
  },
  computed: {
    ...mapState(['defaultTeams']),
    ...mapGetters([
      'components',
      'getComponentName',
      'getCookedAmountTotal',
      'getMeal',
      'getDietName',
      'getDietColor',
      'getSubMeals',
      'getParentMealId',
      'getOrdersForMeal',
      'getOrderSummaryForMeal',
      'getRestrictedTags',
      'getRestrictionText',
      'getMealIngredients'
    ]),
    ingredients() {
      let ingredients = this.getMealIngredients(this.mealId);
      if (!ingredients) {
        console.warn('ingredients not found', {loading: this.loading});
        return [];
      }
      ingredients = ingredients.slice().sort((a, b) => {
        const componentNameA = a.component_name || '__';
        const componentNameB = b.component_name || '__'
        if (componentNameA !== componentNameB) {
          return componentNameA.localeCompare(componentNameB);
        }
        return (a.fooddescriptionsimple || a.fooddescription || '').localeCompare(b.fooddescriptionsimple || b.fooddescription || '');
      });

      // inject steps
      if (this.steps && this.steps.ingredientSteps) {
        console.log('injecting ingredient steps', this.steps.ingredientSteps);
        this.steps.ingredientSteps.forEach(s => {
          const ingredient = ingredients.find(i => s.meal_food_id === i.id);
          if (!ingredient) {
            console.error('failed to find meal food for step', {s, ingredients: this.ingredients});
          } else {
            // console.log('injected ingredient steps', ingredient.fooddescriptionsimple, s.team, s.id);
            ingredient.assigned = s.team;
            ingredient.instruction = s.instruction || ingredient.instruction;
            ingredient.prep_instruction = ingredient.instruction || ingredient.prep_instruction;
          }
        });
      }

      return ingredients;
    },
    orders() {
      return this.date ? this.getOrdersForMeal({date: this.date, mealId: this.mealId}) : [];
    },
    ordersWithRestrictions() {
      return this.orders.filter(o => o.allergies && o.allergies.length > 0);
    },
    orderRestrictions() {
      const restrictions = {};
      this.ordersWithRestrictions.forEach(o => {
        o.allergies.forEach(restriction => {
          restrictions[restriction] = restrictions[restriction] || [];
          const {uid, id, allergyDetected} = o;
          const foodIdToCountMap = allergyDetected ? allergyDetected[restriction] : false;
          restrictions[restriction].push({
            uid,
            id,
            isDetected: !!foodIdToCountMap,
            foodIdToCountMap,
            quantity: o.quantity
          });
        });
      });
      return restrictions;
    },
    orderRestrictionsChips() {
      const result = [];
      // console.log('Object.entries(this.orderRestrictions)', Object.entries(this.orderRestrictions));
      Object
        .entries(this.orderRestrictions)
        .forEach(([restrictionId, orders]) => {
          result.push({
            count: orders.reduce((sum, o) => o.quantity + sum, 0),
            text: this.getRestrictionText(restrictionId),
            name: restrictionId,
            foodIdMap: new Set(orders.flatMap(o => o.foodIdToCountMap ? Object.keys(o.foodIdToCountMap) : []))
          });
        });
      return result.sort((a, b) => a.text.localeCompare(b.text));
    },
    restrictedTagIds() {
      return this.getRestrictedTags(Object.keys(this.orderRestrictions));
      // console.log('restrictedTagIds',
      //     {
      //       'Object.keys(this.orderRestrictions)': Object.keys(this.orderRestrictions),
      //       'this.getRestrictedTags(Object.keys(this.orderRestrictions)': this.getRestrictedTags(Object.keys(this.orderRestrictions))
      //     });
      // return Object.keys(this.orderRestrictions);
    },
    isSubMeal() {
      return !!this.parentMealId;
    },
    notFound() {
      return !this.meal;
    },
    parentMeal() {
      return this.getMeal(this.parentMealId);
    },
    parentMealId() {
      if (this.loading) {
        return false;
      }
      const {meal: parentId} = (this.meal && this.meal.meal_version_parent && this.meal.meal_version_parent[0]) || {};
      return parentId;
    },
    submeals() {
      if (this.submealFeatureEnabled) {
        return this.getSubMeals(this.mealId);
      } else {
        return [];
      }
    },
    activeIngredients() {
      return this.ingredients.filter(i => !i.toDelete);
    },
    meal() {
      return this.getMeal(this.mealId);
    },
    ingredientsByComponent() {
      const result = {};
      // console.log('this.com', this.components);
      this.ingredients.forEach(i => {
        const componentId = i.component_id;
        let componentName = this.getComponentName(componentId);
        if (result[componentName] && result[componentName].id !== componentId) {
          componentName = `${componentName}-${componentId}`;
        }
        result[componentName] = result[componentName] || {
          id: componentId,
          ingredients: []
        };
        result[componentName].ingredients.push(i);
      });
      return result;
    },
    summary() {
      if (this.date) {
        const s = this.getOrderSummaryForMeal({date: this.date, mealId: this.mealId});
        const small = s.small || 0;
        const medium = s.medium || 0;
        const large = s.large || 0;
        const count = small + medium + large;
        return {small, medium, large, count};
      } else {
        return undefined;
      }
    },
    isNameEdit() {
      return !!this.meal.nameEdit;
    },
    newMealOrdersSelected() {
      return this.ordersWithRestrictions.filter(o => {
        for (const a of o.allergies) {
          if (this.newMealOrders[a])
            return true;
        }
        return false;
      })
    },
    hasNotes() {
      function hasNotes(notes) {
        return notes && notes.trim().length > 0;
      }

      return hasNotes(this.meal.notes)
        ||
        Object
          .values(this.ingredientsByComponent)
          .some(component => hasNotes(this.components[component.id].notes))
    },
    mealCost() {
      // console.log('meal cost',this.ingredients);
      const ingredientCosts = this.ingredients
        .filter(i => i.price)
        .map(i => (parseInt(i.price) / 100) * (parseFloat(i.rawAmount) / 1000));
      // console.log('ingredientCosts', ingredientCosts);
      if (ingredientCosts.length > 0) {
        return ingredientCosts.reduce((a, i) => a + i, 0);
      } else {
        return undefined;
      }

    },
    readOnlyRecipe() {
      return this.readOnly && !this.canEditRecipe;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'fetchMeal',
      'fetchMealIngredients',
      'fetchOrders',
      'fetchOrdersForMeal',
      'fetchDiets',
      'fetchRestrictions',
      'fetchSubMeals',
      'createComponent',
      'updateIngredient',
      'updateComponent',
      'removeIngredient',
      'replaceIngredientFood',
      'copyMeal',
      'deleteMeal',
      'updateMeal',
      'updateOrder'
    ]),
    ...mapMutations([
      'CLEAR',
      'ADD_MEAL_INGREDIENT'
    ]),
    fetchData() {
      console.log('fetch detail for meal ', this.mealId);
      this.loading = true;
      return Promise
        .all([
          this.fetchMeal(this.mealId),
          this.submealFeatureEnabled && this.fetchSubMeals({id: this.mealId}),
          this.fetchMealIngredients({id: this.mealId}),
          // this.date && this.fetchOrders(this.date),
          (this.date && this.fetchOrdersForMeal({date: this.date, mealId: this.mealId})),
          this.fetchDiets(),
          this.fetchRestrictions(),
          this.fetchSteps()
        ])
        .finally(() => {
          if (this.meal && !this.meal.name) {
            this.meal.nameEdit = true;
          }
          this.loading = false;
        });
    },
    async fetchSteps() {
      return api.get(`v2/meal/${this.mealId}/steps`)
        .then(({data}) => {
          console.log('got steps', data);
          this.steps = data;
          data.mealComponentSteps.forEach(s => {
            this.assigned.components[s.component_id] = s.team;
          });
        })
        .catch(e => {
          console.error('failed to fetch steps', e);
        })
    },
    async saveComponentAssignment(componentId, team) {
      const existingStep = this.steps.mealComponentSteps.find(s => s.component_id === componentId);
      console.log('saving', {componentId, team, existingStep});
      if (existingStep) {
        //update
        return api.put(`v2/meal/steps/component/update/${existingStep.id}`, {team})
          .then(r => {
            console.log('saved', r);
          });
      } else {
        //create new step
        const payload = {
          meal_id: this.mealId,
          component_id: componentId,
          team
        }
        return api.post(`v2/meal/steps/component`, payload)
          .then(r => {
            console.log('saved', r);
            this.steps.mealComponentSteps.push(r.data);
          });
      }
    },
    saveIngredientAssignment(options) {
      const {id, team, instruction} = options;
      // console.log('saveIngredientAssignment ', options);
      const existingStep = this.steps.ingredientSteps.find(s => s.meal_food_id === id);
      // console.log('saving', {id, team, existingStep});
      const payload = {
        meal_food_id: id,
        team,
        instruction
      }
      if (existingStep) {
        //update
        return api.put(`v2/meal/steps/update/${existingStep.id}`, payload)
          .then(({data}) => {
            const i = this.ingredients.find(i => i.id === id);
            i.assigned = data.team;
            return data;
          });
      } else {
        //create new step
        return api.post(`v2/meal/steps`, payload)
          .then(({data}) => {
            // console.log('saved', data);
            this.steps.ingredientSteps.push(data);
            const i = this.ingredients.find(i => i.id === id);
            i.assigned = data.team;
            return data;
          });
      }
    },
    isDetected(restrictionId) {
      const restriction = this.getRestrictedTags([restrictionId]);
      const activeTags = this.showTags(restriction, this.ingredients);
      // console.log('activeTags', activeTags);
      const detected = Object.keys(activeTags).length > 0;
      //console.log('isDetected', {restrictionName: restrictionId, detected});
      return detected;
    },
    showTags(restrictedTagIds, ingredients) {
      //console.log('restrictedTagIds', restrictedTagIds);
      const tags = {};
      ingredients.forEach(i => {
        (i.tagids || []).forEach(tagId => {
          // console.log('i',[tagId,i.tagids]);
          (restrictedTagIds[tagId] || [])
            .forEach(text => {
              // console.log('i text',text);
              tags[text] = tags[text] || 0;
              tags[text]++;
            });
        });
      });
      // console.log('tags',tags);
      return tags;
    },
    isMonosized(id) {
      return this.components[id] && this.components[id].monosized;
    },
    addIngredient(component_id) {
      const componentName = this.getComponentName(component_id);
      // this.ingredients.push(
      this.ADD_MEAL_INGREDIENT({
        ingredient:
          convertIngredient({
            component_id,
            component: this.components[component_id],
            component_name: componentName,
            fooddescriptionsimple: '',
            editing: true,
            isModified: true,
            mealid: this.meal.id,
            portion_multiplier: .01,
            portion_basis: 1455,
          })
      });
    },
    keyOf(ingredient) {
      return `${ingredient.component_id}:${ingredient.foodid}`;
    },
    async createComponentAndMoveIngredients({component_name, monosized, ingredientsToMove}) {
      try {
        let emptyComponent = true;
        let {id} = await this.createComponent({name: component_name, monosized});
        // console.log('ingredientsToMove', ingredientsToMove);
        for (const i of this.ingredients) {
          if (ingredientsToMove[this.keyOf(i)]) {
            emptyComponent = false;
            await this.replaceIngredientFood({
              oldIngredient: i,
              newIngredient: {
                ...i,
                component_id: id
              }
            });
          }
        }
        // console.log('this.ingredients', this.ingredients.filter(i => ingredientsToMove[i.foodid]));
        if (emptyComponent) {
          this.addIngredient(id);
        }
        this.showComponentDialog = false;
      } catch (e) {
        window.alert('failed to save component.  please try again');
        if (window.Sentry) {
          window.Sentry.setContext('createComponent', {component_name, monosized, ingredientsToMove});
          window.Sentry.captureException(e);
        }
      }
    },
    createSub() {
      this.copyMeal({mealId: this.mealId});
    },
    // async deleteMealAndRedirectToParent() {
    //   console.log('redirect and delete ', {params: {id: this.parentMealId}});
    //   this.loading = true;
    //   await this.deleteMeal({mealId: this.mealId});
    //   await this.fetchSubMeals({id: this.parentMealId, force: true});
    //   return this.$router.push({params: {id: this.parentMealId}});
    // },
    editName() {
      this.$set(this.meal, 'nameEdit', true);
    },
    saveEditName() {
      this.$set(this.meal, 'nameEdit', false);
      // console.log('call update meal in store');
      this.updateMeal({mealId: this.meal.id, update: {name: this.meal.name}});
    },
    showChangeOrderDialog(otherMeal) {
      this.showingChangeOrderDialog = true;
      this.newMeal = otherMeal;
      this.newMealId = otherMeal.id;
      this.newMealOrders = {};
      // console.log('showChangeOrderDialog', {newMeal: otherMeal});
    },
    saveChangeOrder() {
      // console.log('newMealOrders', this.newMealOrdersSelected);
      this.showingChangeOrderDialog = false;
      return Promise.all(this.newMealOrdersSelected.map(o => {
        const {date, uid, id: orderId} = o;
        const update = {meal_id: this.newMealId};
        this.updateOrder({date, uid, orderId, update});
      }));
    },
    async removeComponent(c) {
      if (confirm('Do you want to remove this component from the meal?')) {
        await Promise.all(
          c.ingredients.map(i =>
            this.removeIngredient({ingredient: i})
              .catch(e => {
                console.error('error removing ingredient', e);
              })
          )
        );
      }
    },
    showNutrients(ingredients, size = 'medium') {
      const ratioMap = {
        small: 0.75,
        medium: 1,
        large: 1.25
      }
      const ingredientRoute = {
        name: 'IngredientNutrients',
        // query: Object.fromEntries(ingredients.map(ingredient => [ingredient.foodid, ingredient.amount_in_grams]))
        query: ingredients
          .reduce((sum, i) => {
            let {foodid, amount_in_grams, component} = i;
            if (size !== 'medium' && component) {
              const ratio = component[`${size}_ratio`] || ratioMap[size];
              amount_in_grams *= ratio;
            }
            sum[foodid] = (sum[foodid] || 0) + Number(amount_in_grams || 0);
            return sum;
          }, {})
      };
      // console.log('ingredientRoute', {ingredients, ingredientRoute});
      this.$router.push(ingredientRoute);
    },
    toLineArray(str) {
      return str && str.split('\n');
    },
    filterIngredients(orderRestrictionsChips, ingredients) {
      const foodIds = ingredients.map(i => i.foodid);
      // console.log('filterIngredients',{orderRestrictionsChips, ingredients });
      return orderRestrictionsChips.filter(c => foodIds.some(id => c.foodIdMap.has(id)));
    },
  }
}
</script>

