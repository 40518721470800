import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("Create a new meal component")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")]),_c(VBtn,{on:{"click":_vm.saveComponent}},[_vm._v("Save ")])],1),_c(VCardText,[_c(VForm,{ref:"componentForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,{attrs:{"pa-0":"","ma-0":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Component Name","rules":_vm.componentNameRules,"required":""},model:{value:(_vm.componentName),callback:function ($$v) {_vm.componentName=$$v},expression:"componentName"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCheckbox,{attrs:{"label":"monosized","required":""},model:{value:(_vm.monosized),callback:function ($$v) {_vm.monosized=$$v},expression:"monosized"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_vm._v("Select the ingredients that you'd like to move into this component. If you don't select any ingredients a new component will be created.")]),_vm._l((_vm.ingredientsByComponent),function(component,name){return _c(VCol,{key:component.id,attrs:{"cols":"4"}},[_c('h4',[_vm._v(" "+_vm._s(name)+" ")]),_c(VList,{attrs:{"dense":""}},_vm._l((component.ingredients),function(ingredient,index){return _c(VListItem,{key:index,staticClass:"pa-0 ma-0",attrs:{"dense":""}},[_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"label":_vm.formatFood(ingredient),"required":"","dense":""},model:{value:(_vm.components[_vm.keyOf(ingredient)]),callback:function ($$v) {_vm.$set(_vm.components, _vm.keyOf(ingredient), $$v)},expression:"components[keyOf(ingredient)]"}})],1)}),1)],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }