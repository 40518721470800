<template>
  <v-card flat>
    <v-card-title>
      <v-btn @click="$emit('close')">Cancel</v-btn>
      <v-spacer/>
      Copy Meal Prep Assignments
      <v-spacer/>
      <v-btn @click="confirmCopy" color="warning">Copy</v-btn>

    </v-card-title>
    <template v-if="loading">
      <v-card-text v-if="loading">
        <v-progress-circular indeterminate/>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text>
        copying from {{ sourceMeal.date }} {{ sourceMeal.name }} ({{ source }})
        to
        {{ destinationMeal.date }}
        {{ destinationMeal.name }} ({{ destination }})>
        <h4>Ingredient Prep Steps</h4>
        <v-simple-table dense>
          <thead>
          <tr>
            <th>Component</th>
            <th>Ingredient</th>
            <th>Team</th>
            <th>Instruction</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="step of steps.ingredientSteps"
              v-bind:key="step.id"
          >
            <td>{{ step.componentName }}</td>
            <td>
              {{ step.ingredientName }}
              <v-alert
                v-if="step.ingredientMissing"
                type="warning"
                dense
                text
              >ingredient does not exist in current meal ({{destination}})
              </v-alert>
            </td>
            <td>{{ step.team }}</td>
            <td>{{ step.instruction }}</td>
          </tr>
          </tbody>
        </v-simple-table>

        <h4>Component Prep Steps</h4>
        <v-simple-table dense>
          <thead>
          <tr>
            <th>Component</th>
            <th>Team</th>
            <th>Instruction</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="step of steps.mealComponentSteps" v-bind:key="step.id">
            <td>{{ step.componentName }}
              <v-alert
                v-if="step.componentMissing"
                type="warning"
                dense
                text
              >component does not exist in current meal ({{destination}})
              </v-alert>

            </td>
            <td>{{ step.team }}</td>
            <td>{{ step.instruction }}</td>
          </tr>
          </tbody>
        </v-simple-table>
        <!--        {{ assigned }}-->
        <!--      </pre>-->
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import api from '@/api';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "PrepAssignmentCopy",
  props: {
    source: {},
    destination: {}
  },
  data() {
    return {
      mealId: null,
      steps: {
        ingredientSteps: [],
        componentSteps: [],
      },
      assigned: {
        components: {},
      },
      loading: true
    };
  },
  mounted() {
    this.mealId = this.source;
    return this.fetchData();
  },
  methods: {
    ...mapActions(['fetchMealIngredients', 'fetchMeal']),
    fetchData() {
      this.loading = true;
      // console.log('loading', this.loading);
      return Promise
        .all([
          this.fetchMeal(this.mealId),
          this.fetchMeal(this.destination),
          this.fetchMealIngredients({id:this.mealId}),
          this.fetchSteps(this.mealId)
        ])
        .then(() => this.injectIngredientSteps())
        .finally(() => this.loading = false)
      // .finally(() => console.log('loading', this.loading));
    },
    async fetchSteps() {
      return api.get(`v2/meal/${this.mealId}/steps`)
        .then(({data}) => {
          console.log('got steps', data);
          this.steps = data;
        })
        .catch(e => {
          console.error('failed to fetch steps', e);
        })
    },
    injectIngredientSteps() {

      if (this.steps) {
        const destinationIngredients = this.getMealIngredients(this.destination);
        this.steps.mealComponentSteps.forEach(s => {
          this.assigned.components[s.component_id] = s.team;

          const destinationComponentExists = destinationIngredients.find(i => i.component_id === s.component_id);
          if (!destinationComponentExists) {
            console.warn('could not find component in destination', s);
            s.componentMissing = true;
          }

        });
        if (this.steps.ingredientSteps) {
          console.log('injecting ingredient steps');
          this.steps.mealComponentSteps.forEach(s => {
            const component = this.getComponent(s.component_id);
            s.componentName = (component && component.name) || 'not found';
          });

          this.steps.ingredientSteps.forEach(s => {
            const ingredient = this.ingredients.find(i => s.meal_food_id === i.id);

            if (!ingredient) {
              console.error('failed to find meal food for step', {s, ingredients: this.ingredients});
            } else {
              s.ingredientName = ingredient.fooddescriptionsimple || `${ingredient.fooddescription} *`;
              s.componentName = ingredient.component && ingredient.component.name;

              const destinationIngredient = destinationIngredients
                .find(i => ingredient.foodid === i.foodid && ingredient.component_id === i.component_id);
              if (!destinationIngredient) {
                console.warn('failed to find destination meal food for step', {
                  s,
                  ingredients: destinationIngredients
                });
                s.ingredientMissing = true;
              }
            }
          });
        }
      }
    },
    confirmCopy() {
      if (confirm(`this will erase any existing prep instructions or assignments in
      ${this.destinationMeal.date} ${this.destinationMeal.name}
      there is no undo, so make sure this is correct
      `)) {
        api.post('v2/meal/copy-steps', {from: this.source, to: this.destination})
          .then(() => {
            this.$emit('close');
            // FIXME: better state management
            window.location.reload();
          })
      }
    }
  },
  computed: {
    ...mapGetters(['getMeal', 'getMealIngredients', 'getComponent']),
    sourceMeal() {
      return this.getMeal(this.source) || {};
    },
    destinationMeal() {
      return this.getMeal(this.destination) || {};
    },
    ingredients() {
      let ingredients = this.getMealIngredients(this.mealId);
      if (!ingredients) {
        console.warn('ingredients not found', {loading: this.loading});
        return [];
      }
      return ingredients.slice().sort((a, b) => {
        const componentNameA = a.component_name || '__';
        const componentNameB = b.component_name || '__'
        if (componentNameA !== componentNameB) {
          return componentNameA.localeCompare(componentNameB);
        }
        return (a.fooddescriptionsimple || a.fooddescription || '').localeCompare(b.fooddescriptionsimple || b.fooddescription || '');
      });
    },
  }
}
</script>

