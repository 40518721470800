<template>
  <v-container fluid class="ma-0" v-if="!loading">
    <template v-if="onlyProblems && restrictionsDetected === 0">
      <v-row>
        <v-col>no meal restrictions detected</v-col>
      </v-row>
    </template>
    <template v-if="!onlyProblems || restrictionsDetected > 0">
      <v-row>
        <span v-for="(ingredient,i) of ingredientsFiltered" v-bind:key="i">
          <span v-if="isRestrictionDetected(ingredient)" style="color: red">
            {{ lowerCase(ingredient.name || ingredient.fooddescriptionsimple) }}<v-icon small>mdi-alert</v-icon>
            <span v-html="addCommaIfNeeded('', i, ingredientsFiltered.length)"></span>
          </span>
          <span v-else-if="!onlyProblems">{{ lowerCase(ingredient.name || ingredient.fooddescriptionsimple) }} <span
            v-html="addCommaIfNeeded('', i, ingredientsFiltered.length)"></span></span>
          </span>
        <span v-if="selectedComponentId" class="font-weight-bold">
           ({{ totalAllergy }} ALLERGY)
        </span>
      </v-row>

      <v-container mt-4 pa-0 style="font-size: smaller"
                   fluid>
        <v-row>
          <v-col class="ma-0 pa-1" v-for="size of ['small','medium','large']" v-bind:key="size">
            <v-row style="line-height: 1.1; font-weight: bold"
                   class="ma-0 pa-0"
                   dense
            >
              <v-col cols="2" class="text-right ma-0 py-0">
                {{ orderSummary[size] ? orderSummary[size] : '' }}
              </v-col>
              <v-col cols="10" class="ma-0 py-0">
                {{ orderSummary[size] ? getSizeTitle(size) : '' }}
              </v-col>
            </v-row>
            <v-row style="line-height: 1.1; font-weight: bold"
                   class="ma-0 pa-0"
                   dense
            >
              <v-col cols="2" class="text-right ma-0 py-0">
                {{ orderSummary[`${size}-regular`] ? orderSummary[`${size}-regular`] : '' }}
              </v-col>
              <v-col cols="10" class="ma-0 py-0">
                {{ orderSummary[`${size}-allergy`] ? '+ ' + orderSummary[`${size}-allergy`] + ' ALLERGY' : '' }}
                <!--                {{ orderSummary[size] ? getSizeTitle(size) : '' }}-->
              </v-col>
            </v-row>

            <v-row
              v-for="(s,restrictionId) of restrictionsBySize[size]"
              v-bind:key="restrictionId"
              style="line-height: 1.1"
              class="ma-0 pa-0"
              dense
            >
              <template v-if="s.count>0 && (s.detected || !onlyProblems)">
                <v-col cols="2" class="ma-0 py-0 text-right">
                  {{ s.count }}
                </v-col>
                <v-col cols="10" class="ma-0 py-0">
                <span v-if="s.detected" style="color: red">
                  {{ s.detected }} <v-icon small>mdi-alert</v-icon>
                </span>
                  <span v-if="s.notDetected">
                  {{ s.notDetected }}
                </span>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {filterSelectedIngredients} from "@/store/utils";


export default {
  name: 'MealRestrictionSummary',
  props: {
    orders: {},
    meal: {},
    ingredients: {},
    selectedComponentId: {type: Number, default: null, required: false},
    onlyProblems: {type: Boolean, default: false, required: false}
  },
  mounted() {
    return Promise.all([this.fetchRestrictions(), this.fetchStreams(), this.fetchDiets()])
      .finally(() => this.loading = false);
  },
  methods: {
    ...mapActions(['fetchRestrictions', 'fetchStreams', 'fetchDiets']),
    getSizeTitle(size) {
      try {
        return this.stream.sizes[size].kitchen_description;
      } catch (e) {
        // console.log('failed', e);
        return size.toUpperCase();
      }
    },
    lowerCase(s) {
      return s && s.toLowerCase();
    },
    addCommaIfNeeded(str, i, length) {
      if (i < length - 1) {
        return str + ',&nbsp;';
      } else {
        return str;
      }
    },
    isRestrictionDetected(ingredient) {
      return !!this.restrictionsByFoodId[ingredient.food_id || ingredient.foodid]
    }
  },
  computed: {
    ...mapGetters(['getRestrictionText', 'getStream', 'getDietStream', 'getMealIngredients']),
    restrictionsByFoodId() {
      const result = {};
      this.orders.forEach(o => {
        if (o.allergyDetected) {
          Object.keys(o.allergyDetected)
            .forEach(restrictionId => {
              Object.keys(o.allergyDetected[restrictionId])
                .forEach(foodid => {
                  result[foodid] = true;
                })
            })
        }
      });
      return result;
    },
    selectedFoodIds() {
      return this.ingredientsFiltered.map(i => (i.food_id || i.foodid));
    },
    restrictionsBySize() {
      // console.log('restrictionsBySize start');
      const summary = {
        small: {Regular: {count: 0}},
        medium: {Regular: {count: 0}},
        large: {Regular: {count: 0}}
      };

      const key = (allergies) => {
        if (allergies && allergies.length > 0) {
          return allergies.map(a => this.getRestrictionText(a)).sort().join(', ').toUpperCase();
        } else {
          return "Regular";
        }
      }


      this.orders.forEach(o => {
        const {meal_size, allergies} = o;

        const allergyDetected = filterSelectedIngredients(this.selectedFoodIds, o.allergyDetected);
        const allergyKey = key(allergies);
        summary[meal_size][allergyKey] = summary[meal_size][allergyKey] || {count: 0};
        summary[meal_size][allergyKey].count += o.quantity;

        if (o.quantity > 0) {
          if (allergyKey === 'Regular') {
            summary[meal_size][allergyKey].notDetected = 'Regular';
          } else if (allergies) {
            summary[meal_size][allergyKey].detected =
              // (allergies && allergies.filter(allergy => this.isRestrictionTypeDetected(meal_id, allergy)) || [])
              (allergyDetected && Object.keys(allergyDetected) || [])
                .map(a => this.getRestrictionText(a).toUpperCase())
                .sort().join(', ');
            summary[meal_size][allergyKey].notDetected =
              // (allergies && allergies.filter(allergy => !this.isRestrictionTypeDetected(meal_id, allergy)) || [])
              (allergies && allergies.filter(allergy => !(allergyDetected && allergyDetected[allergy])) || [])
                .map(a => this.getRestrictionText(a).toLowerCase())
                .sort().join(', ');
          }
        }
      });

      // console.log('mealSummaryBySize', summary);
      return summary;
    },
    orderSummary() {
      const resultBySize = {};

      function addQty(key, quantity) {
        resultBySize[key] = resultBySize[key] || 0;
        resultBySize[key] += quantity;
      }

      this.orders.forEach(o => {
        const mealSize = o.meal_size;
        addQty(mealSize, o.quantity);

        const allergyDetected = o.allergyDetected ? filterSelectedIngredients(this.selectedFoodIds, o.allergyDetected) : {};
        // console.log('o.allergyDetected', o.allergyDetected, allergyDetected);
        if (Object.keys(allergyDetected).length > 0) {
          addQty(`${mealSize}-allergy`, o.quantity);
          addQty(`allergy`, o.quantity);
        } else {
          addQty(`${mealSize}-regular`, o.quantity);
        }
      })
      return resultBySize;
    },

    stream() {
      const diet = this.meal.diet;
      return (diet === 'chefs_choice' ? {} : this.getStream(this.getDietStream(diet)));
    },
    ingredientsFiltered() {
      // passed in as a prop
      if (this.ingredients) {
        return this.ingredients;
      }
      if (this.selectedComponentId) {
        const filteredList = this.getMealIngredients(this.meal.id).filter(i => i.component_id === this.selectedComponentId).map(i => i.foodid);
        return this.meal.ingredients.filter(i => filteredList.includes(`${i.food_id || i.foodid}`));
      } else {
        return this.meal.ingredients || []
      }
    },
    restrictionsDetected() {
      return this.orderSummary.allergy;
    },
    totalAllergy() {
      return this.orderSummary.allergy;
    }
  },
  data() {
    return {
      loading: true
    }
  }
}
</script>
